import axios from 'axios';

class SurveyService {
    static async getAll(token) {
        try {
            const response = await axios.get('/api/surveys', {
                headers: {
                    'x-auth-token': token,
                },
            });
            return response.data;
        } catch (err) {
            return null;
        }
    }

    static PENDING = 0;
    static ACTIVE = 1;

    static async post(state, token) {
        try {
            const response = await axios.post('/api/surveys', state, {
                headers: {
                    'x-auth-token': token,
                },
            });
            return response.data;
        } catch (err) {
            return false;
        }
    }

    static async delete(id, token) {
        try {
            await axios.delete(`/api/surveys/${id}`, {
                headers: {
                    'x-auth-token': token,
                },
            });
            return true;
        } catch (err) {
            return false;
        }
    }

    static async deleteSingle(id, token) {
        try {
            await axios.delete(`/api/surveys/uid/${id}`, {
                headers: {
                    'x-auth-token': token,
                },
            });
            return true;
        } catch (err) {
            return false;
        }
    }

    static async duplicate(id, state, token) {
        try {
            const response = await axios.post(`/api/surveys/duplicate/${id}`, state, {
                headers: {
                    'x-auth-token': token,
                },
            });
            return response.data;
        } catch (err) {
            return false;
        }
    }

    static async getById(id, token) {
        try {
            const response = await axios.get(`/api/surveys/${id}`, {
                headers: {
                    'x-auth-token': token,
                },
            });
            return response.data;
        } catch (err) {
            return null;
        }
    }

    static async getByIdWithAnswers(id, token, uid) {
        try {
            const response = await axios.get(`/api/surveys/${id}?uid=${uid}`, {
                headers: {
                    'x-auth-token': token,
                },
            });
            return response.data;
        } catch (err) {
            return null;
        }
    }

    static async getQuestions(id, token) {
        try {
            const response = await axios.get(`/api/surveys/${id}/questions`, {
                headers: {
                    'x-auth-token': token,
                },
            });
            return response.data;
        } catch (err) {
            return null;
        }
    }

    static async getResults(id, token) {
        try {
            const response = await axios.get(`/api/surveys/${id}/results`, {
                headers: {
                    'x-auth-token': token,
                },
            });
            return response.data;
        } catch (err) {
            return null;
        }
    }

    static async getResultsCsv(id, token) {
        try {
            const response = await axios.get(`/api/surveys/${id}/results?csv=1`, {
                headers: {
                    'x-auth-token': token,
                },
            });
            console.log(response.data);
            return response.data;
        } catch (err) {
            return null;
        }
    }

    static async postAnswers(id, data, token) {
        try {
            const response = await axios.post(`/api/surveys/${id}/answers`, data, {
                headers: {
                    'x-auth-token': token,
                },
            });

            return response;
        } catch (err) {
            return false;
        }
    }
    static async getFullfillments(id, token) {
        try {
            const response = await axios.get(`/api/surveys/${id}/fullfillments`, {
                headers: {
                    'x-auth-token': token,
                },
            });

            return response;
        } catch (err) {
            return null;
        }
    }

    static async activate(id, token) {
        try {
            const response = await axios.put(
                `/api/surveys/${id}`,
                {
                    status: 1,
                },
                {
                    headers: {
                        'x-auth-token': token,
                    },
                }
            );
            return response.data;
        } catch (err) {
            return null;
        }
    }

    static async revoke(id, token) {
        try {
            const response = await axios.put(
                `/api/surveys/${id}`,
                {
                    status: 0,
                },
                {
                    headers: {
                        'x-auth-token': token,
                    },
                }
            );
            return response.data;
        } catch (err) {
            return null;
        }
    }
}

export default SurveyService;
