import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: 18,
        fontWeight: 600,
    },
}));

const Content = ({ title, children }) => {
    /**
     * CSS
     */
    const classes = useStyles();

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography className={classes.title} variant='h1' component='h1'>
                    {title}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                {children}
            </Grid>
        </Grid>
    );
};

export default Content;
