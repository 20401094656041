import React, { Fragment, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import PollIcon from '@material-ui/icons/Poll';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import { NavLink } from 'react-router-dom';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import AuthContext from '../context/AuthContext';

const useStyles = makeStyles({
    root: {
        width: '100%',
        justifyContent: 'flex-start',
    },
    selected: {},
});

const TopNavigation = () => {
    const { auth } = useContext(AuthContext);
    const classes = useStyles();

    return (
        <BottomNavigation showLabels className={classes.root}>
            <BottomNavigationAction component={NavLink} to='/' label='Umfragen' icon={<PollIcon />} exact={true} />

            {auth && auth.role === 'ROLE_ADMIN' && (
                <BottomNavigationAction
                    component={NavLink}
                    to='/bereiche'
                    label='Bereiche'
                    icon={<AccountTreeIcon />}
                />
            )}
            {auth && auth.role === 'ROLE_ADMIN' && (
                <BottomNavigationAction
                    component={NavLink}
                    to='/benutzer'
                    label='Benutzer'
                    icon={<SupervisorAccountIcon />}
                />
            )}
        </BottomNavigation>
    );
};

export default TopNavigation;
