import {
    Grid,
    TextField,
    Typography,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    CircularProgress,
    Checkbox,
} from '@material-ui/core';
import React, { useContext, useEffect, useState, Fragment } from 'react';
import Content from '../components/Content/Content';
import { makeStyles } from '@material-ui/core/styles';
import SectionService from '../services/SectionService';
import AuthContext from '../context/AuthContext';
import MuiAlert from '@material-ui/lab/Alert';
import SortableTree from 'react-sortable-tree';
import Button from '../components/Button/Button';
import SendIcon from '@material-ui/icons/Send';
import UserService from '../services/UserService';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
function Alert(props) {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: 18,
        fontWeight: 600,
    },
    margin: {
        marginTop: 24,
    },
}));

const UserForm = () => {
    /**
     * CSS
     */
    const classes = useStyles();

    const { id } = useParams();

    /**
     * STATES
     */
    const [state, setState] = useState({
        username: '',
        password: '',
        password_confirm: '',
        role: 'ROLE_USER',
        section: 0,
        link: ''
    });
    const [sections, setSections] = useState([]);
    const [fetchError, setFetchError] = useState(false);
    const [usernameError, setUsernameError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [sectionError, setSectionError] = useState(false);
    const [postError, setPostError] = useState(false);
    const [passwordConfirmError, setPasswordConfirmError] = useState(false);
    const [loading, setLoading] = useState(true);
    const { auth } = useContext(AuthContext);
    const history = useHistory();

    /**
     * HOOKS
     */
    useEffect(() => {
        async function fetch() {
            const response = await SectionService.get(auth.token);
            if (response) {
                setSections(response);
                if (!id) {
                    setLoading(false);
                } else {
                    fetchUser();
                }
            } else {
                setLoading(false);
                setFetchError(true);
            }
        }
        async function fetchUser() {
            const response = await axios.get(`/api/users/${id}`, {
                headers: {
                    'x-auth-token': auth.token,
                },
            });
            setState(response.data);
            setLoading(false);
        }
        fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * CONTROLS
     */
    const change = (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    };

    const selectSection = ({ node, path, treeIndex, lowerSiblingCounts: _lowerSiblingCounts }, e) => {
        setState({ ...state, section: node.id });
    };

    /**
     * SUBMIT
     */
    const validate = () => {
        let error = false;

        if (state.username === '') {
            setUsernameError(true);
            error = true;
        } else {
            setUsernameError(false);
        }

        if (!id) {
            if (state.password === '') {
                setPasswordError(true);
                error = true;
            } else {
                setPasswordError(false);
            }
        }

        if (!id) {
            if (state.password !== state.password_confirm) {
                setPasswordConfirmError(true);
                error = true;
            } else {
                setPasswordConfirmError(false);
            }
        }

        if (state.role !== 'ROLE_ADMIN' && state.section === 0) {
            setSectionError(true);
            error = true;
        } else {
            setSectionError(false);
        }

        return !error;
    };

    const submit = async (e) => {
        e.preventDefault();
        if (validate()) {
            if (!id) {
                const response = await UserService.post(state, auth.token);
                if (response) {
                    history.push('/benutzer');
                } else {
                    setPostError(true);
                }
            } else {
                if (state.password) {
                    if (state.password === '') {
                        setPasswordError(true);
                        return;
                    }
                    if (state.password !== state.password_confirm) {
                        setPasswordConfirmError(true);
                        return;
                    }
                }
                try {
                    await axios.put(`/api/users/${id}`, state, {
                        headers: {
                            'x-auth-token': auth.token,
                        },
                    });
                    history.push('/benutzer');
                } catch (err) {
                    setPostError(true);
                }
            }
        }

        return false;
    };

    return (
        <Content title='Neuen Benutzer erstellen'>
            {loading && (
                <Grid container spacing={2} justify='center'>
                    <Grid item>
                        <CircularProgress />
                    </Grid>
                </Grid>
            )}
            {fetchError && (
                <Grid item xs={12}>
                    <Alert severity='error'>Fehler beim Abrufen der Daten!</Alert>
                </Grid>
            )}
            {!loading && !fetchError && (
                <form onSubmit={submit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                name='username'
                                id='username'
                                label='Benutzername'
                                type='text'
                                value={state.username}
                                fullWidth
                                onChange={change}
                                error={usernameError}
                                helperText={usernameError && 'Benutzername muss ausgefüllt sein'}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name='password'
                                id='password'
                                label='Passwort'
                                type='password'
                                value={state.password}
                                fullWidth
                                onChange={change}
                                error={passwordError}
                                helperText={passwordError && 'Passwort muss ausgefüllt sein'}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name='password_confirm'
                                id='password_confirm'
                                label='Password bestätigen'
                                type='password'
                                value={state.password_confirm}
                                fullWidth
                                onChange={change}
                                error={passwordConfirmError}
                                helperText={passwordConfirmError && 'Passwörter stimmen nicht überein'}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id='role-label'>Rolle</InputLabel>
                                <Select labelId='role-label' id='role' name='role' value={state.role} onChange={change}>
                                    <MenuItem value='ROLE_USER'>Benutzer</MenuItem>
                                    <MenuItem value='ROLE_LEADER'>Leiter</MenuItem>
                                    <MenuItem value='ROLE_ADMIN'>Administrator</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name='link'
                                id='link'
                                label='Link zum DownloadCenter'
                                type='test'
                                value={state.link}
                                fullWidth
                                onChange={change}
                            />
                        </Grid>
                        {(state.role === 'ROLE_USER' || state.role === 'ROLE_LEADER') && (
                            <Fragment>
                                <Grid className={classes.margin} item xs={12}>
                                    <Typography variant='h1' component='h1' className={classes.title}>
                                        Bereich wählen
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <SortableTree
                                        isVirtualized={false}
                                        treeData={sections}
                                        canDrag={false}
                                        generateNodeProps={(rowInfo) => ({
                                            buttons: [
                                                <Checkbox
                                                    className='section-select'
                                                    color='primary'
                                                    checked={state.section === rowInfo.node.id}
                                                    onClick={(e) => selectSection(rowInfo, e)}
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                />,
                                            ],
                                        })}
                                    />
                                </Grid>
                            </Fragment>
                        )}
                        {sectionError && (
                            <Grid item xs={12}>
                                <Alert severity='error'>Bitte einen Bereich für den Benutzer auswählen</Alert>
                            </Grid>
                        )}
                        {postError && (
                            <Grid item xs={12}>
                                <Alert severity='error'>
                                    Beim speichern ist ein Fehler aufgetreten! Überprüfen Sie, ob der Benutzername
                                    bereits vergeben ist.
                                </Alert>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Button type='submit' variant='contained' color='primary' endIcon={<SendIcon />}>
                                Speichern
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            )}
        </Content>
    );
};

export default UserForm;
