import { FormControlLabel, Grid, makeStyles, Radio, TextField } from '@material-ui/core';
import React, { Fragment, useState } from 'react';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: 24,
    },
}));

const RadioQuestion = ({ question, index, onUpdate }) => {
    /**
     * CSS
     */
    const classes = useStyles();

    /**
     * state
     */
    const [value, setValue] = useState('-1');

    /**
     * CONTROLS
     */
    const change = (e) => {
        setValue(e.target.value);
    };

    const changeText = (e) => {
        onUpdate(index, { ...question, text: e.target.value });
    };

    return (
        <Grid container spacing={2} className={classes.container}>
            <Grid item xs={12}>
                <TextField required onChange={changeText} value={question.text || ''} fullWidth label='Frage' />
            </Grid>
            {question.answers.length === 0 && question.type === 'yesno' && (
                <Fragment>
                    <Grid item xs={12} sm={4}>
                        <FormControlLabel
                            onChange={change}
                            control={<Radio />}
                            label='Ja'
                            labelPlacement='end'
                            value={0}
                            checked={value === '0'}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControlLabel
                            onChange={change}
                            control={<Radio />}
                            label='Nein'
                            labelPlacement='end'
                            value={1}
                            checked={value === '1'}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControlLabel
                            onChange={change}
                            control={<Radio />}
                            label='Trifft nicht zu'
                            labelPlacement='end'
                            value={1}
                            checked={value === '2'}
                        />
                    </Grid>
                </Fragment>
            )}
            {question.answers.map((answer, index) => (
                <Grid item xs={12} sm={4}>
                    <FormControlLabel
                        onChange={change}
                        control={<Radio />}
                        label={answer.text}
                        labelPlacement='end'
                        value={index}
                        checked={value === String(index)}
                    />
                </Grid>
            ))}
        </Grid>
    );
};

export default RadioQuestion;
