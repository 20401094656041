import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Link,
    CircularProgress,
    Tooltip,
    Modal,
    Fade,
    Typography,
    Grid,
    Backdrop,
    Button as MButton,
} from '@material-ui/core';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SurveyService from '../../services/SurveyService';
import AuthContext from '../../context/AuthContext';
import { Link as RLink } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import PublishIcon from '@material-ui/icons/Publish';
import GetAppIcon from '@material-ui/icons/GetApp';
import ContentCopyIcon from '@material-ui/icons/FileCopy';
import { Delete as DeleteIcon } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    table: {
        width: '100%',
    },
    publish: {
        color: '#4caf50',
    },
    revoke: {
        color: '#f44336',
    },
    title: {
        fontSize: 16,
        fontWeight: 600,
    },
    container: {
        backgroundColor: '#eee',
        padding: 6,
    },
    grow: {
        flexGrow: 1,
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #39AC39',
        borderRadius: 5,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

const SurveyList = () => {
    /**
     * CSS
     */
    const classes = useStyles();

    /**
     * STATES
     */
    const [surveys, setSurveys] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [activateError, setActivateError] = useState(false);
    const { auth } = useContext(AuthContext);
    const [open, setOpen] = useState(false);
    const [toDelete, setToDelete] = useState(0);

    /**
     * HELPER
     */
    const getBaseUrl = () => {
        const regex = /(https?:\/\/.+?\/)(.*)/;
        const matches = regex.exec(window.location.href);
        return matches[1];
    };

    /**
     * QUERIES
     */
    async function fetch() {
        const data = await SurveyService.getAll(auth.token);
        if (data) {
            setSurveys(data);
            setLoading(false);
        } else {
            setLoading(false);
            setError(true);
        }
    }

    /**
     * HOOKS
     */
    useEffect(() => {
        fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * SUBMIT
     */

    const activate = async (id) => {
        const response = await SurveyService.activate(id, auth.token);
        if (!response) {
            setActivateError(true);
        } else {
            fetch();
        }
    };

    const revoke = async (id) => {
        const response = await SurveyService.revoke(id, auth.token);
        if (!response) {
            setActivateError(true);
        } else {
            fetch();
        }
    };

    const deleteSurveyModal = (id) => {
        setToDelete(id);
        setOpen(true);
    };

    const closeModal = (e) => {
        setOpen(false);
    };

    const deleteSurvey = async () => {
        await SurveyService.delete(toDelete, auth.token);
        fetch();
        setOpen(false);
    };

    return (
        <Fragment>
            <TableContainer>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Titel</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Bereich</TableCell>
                            <TableCell>Auswertung</TableCell>
                            <TableCell>Umfragelink</TableCell>
                            <TableCell align='right'>Aktionen</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading && (
                            <TableRow>
                                <TableCell align='center' colSpan={6}>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        )}
                        {error && (
                            <TableRow>
                                <TableCell align='center' colSpan={6}>
                                    Fehler beim Abrufen der Daten
                                </TableCell>
                            </TableRow>
                        )}
                        {surveys.map((survey, index) => (
                            <TableRow key={index}>
                                <TableCell></TableCell>
                                <TableCell>
                                    {auth.role === 'ROLE_ADMIN' ? (
                                        <Link component={RLink} to={`/umfrage/bearbeiten/${survey.id}`}>
                                            {survey.title}
                                        </Link>
                                    ) : (
                                        survey.title
                                    )}
                                </TableCell>
                                <TableCell>
                                    {survey.status === SurveyService.PENDING && 'Entwurf'}
                                    {survey.status === SurveyService.ACTIVE && 'Veröffentlicht'}
                                </TableCell>
                                <TableCell>{survey.section}</TableCell>
                                <TableCell>
                                    {survey.status === SurveyService.ACTIVE && (
                                        <Fragment>
                                            <Link component={RLink} to={`/umfrage/auswertung/${survey.id}`}>
                                                Auswertung
                                            </Link>{' '}
                                            <Link component={RLink} to={`/umfrage/details/${survey.id}`}>
                                                ({survey.fullfillments.length})
                                            </Link>{' '}
                                            <Link component={RLink} download target='_blank' to={`/api/csv/${survey.id}?token=${auth.token}`}>
                                                (csv)
                                            </Link>
                                            
                                        </Fragment>
                                    )}
                                </TableCell>
                                <TableCell>
                                    {survey.status === SurveyService.ACTIVE && (
                                        <Link component={RLink} to={`/umfrage/${survey.id}`}>
                                            {`${getBaseUrl()}umfrage/${survey.id}`}
                                        </Link>
                                    )}
                                </TableCell>
                                <TableCell align='right'>
                                    {auth.role === 'ROLE_ADMIN' && (
                                        <Fragment>
                                            <Tooltip title='Duplizieren'>
                                                <IconButton
                                                    size='small'
                                                    aria-label='duplizieren'
                                                    color='primary'
                                                    component={RLink}
                                                    to={`/umfrage-duplizieren/${survey.id}`}
                                                >
                                                    <ContentCopyIcon />
                                                </IconButton>
                                            </Tooltip>
                                            {survey.status === SurveyService.PENDING && (
                                                <Tooltip title='Veröffentlichen'>
                                                    <IconButton
                                                        onClick={(e) => activate(survey.id)}
                                                        size='small'
                                                        className={classes.publish}
                                                        aria-label='veröffentlichen'
                                                    >
                                                        <PublishIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                            {survey.status === SurveyService.ACTIVE && (
                                                <Tooltip title='Sperren'>
                                                    <IconButton
                                                        onClick={(e) => revoke(survey.id)}
                                                        size='small'
                                                        className={classes.revoke}
                                                        aria-label='sperren'
                                                    >
                                                        <GetAppIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                            <Tooltip title='Löschen'>
                                                <IconButton
                                                    onClick={(e) => deleteSurveyModal(survey.id)}
                                                    size='small'
                                                    className={classes.revoke}
                                                    aria-label='loeschen'
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Fragment>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Modal
                aria-labelledby='delete-modal-title'
                aria-describedby='delete-modal-description'
                open={open}
                closeAfterTransition
                className={classes.modal}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <Typography component='h4' variant='h4' id='transition-modal-title'>
                            Löschen?
                        </Typography>
                        <div id='transition-modal-description'>
                            <p>
                                <Typography>
                                    Möchten Sie die Umfrage wirklich löschen? Dieser Vorgang kann nicht rückgängig
                                    gemacht werden.
                                </Typography>
                            </p>
                            <Grid container spacing={2} justify='space-between'>
                                <Grid item>
                                    <MButton onClick={closeModal} variant='outlined' color='primary'>
                                        Abbrechen
                                    </MButton>
                                </Grid>
                                <Grid item>
                                    <MButton onClick={deleteSurvey} variant='outlined' color='secondary'>
                                        Löschen
                                    </MButton>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </Fragment>
    );
};

export default SurveyList;
