import React, { Fragment, useContext } from 'react';
import TopNavigation from '../parts/TopNavigation';
import Protected from '../components/Protected/Protected';
import { Container } from '@material-ui/core';
import { Route, Switch } from 'react-router';
import Surveys from '../parts/Surveys';
import Header from '../parts/Header';
import { makeStyles } from '@material-ui/core';
import SurveyForm from '../forms/SurveyForm';
import SurveyDetailForm from '../forms/SurveyDetailForm';
import SectionForm from '../forms/SectionForm';
import Users from '../parts/Users';
import UserForm from '../forms/UserForm';
import AuthContext from '../context/AuthContext';
import Page403 from './Page403';
import PageResult from './PageResult';
import Details from '../components/SurveyList/Details';
import PageView from './PageView';

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: 24,
    },
}));
const PageDashboard = () => {
    /**
     * CSS
     */
    const classes = useStyles();

    /**
     * STATES
     */
    const { auth } = useContext(AuthContext);

    return (
        <Protected>
            <header>
                <Container maxWidth='lg'>
                    <Header />
                    {auth && (auth.role === 'ROLE_ADMIN' || auth.role === 'ROLE_LEADER') && <TopNavigation />}
                </Container>
            </header>
            {auth && (
                <Container className={classes.container} maxWidth='lg'>
                    <Switch>
                        {auth.role === 'ROLE_ADMIN' || auth.role === 'ROLE_LEADER' ? (
                            <Fragment>
                                {auth.role === 'ROLE_ADMIN' && (
                                    <Fragment>
                                        <Route exact path='/neue-umfrage' component={SurveyForm} />
                                        <Route exact path='/umfrage-duplizieren/:id' component={SurveyForm} />
                                        <Route exact path='/neuer-benutzer' component={UserForm} />
                                        <Route exact path='/benutzer/bearbeiten/:id' component={UserForm} />
                                        <Route exact path='/umfrage/bearbeiten/:id' component={SurveyDetailForm} />
                                        <Route exact path='/bereiche' component={SectionForm} />
                                        <Route exact path='/benutzer' component={Users} />
                                    </Fragment>
                                )}
                                <Route exact path='/umfrage/auswertung/:id' component={PageResult} />
                                <Route exact path='/umfrage/ansicht/:id/:uid' component={PageView} />
                                <Route exact path='/umfrage/details/:id' component={Details} />
                                <Route exact path='/' component={Surveys} />
                            </Fragment>
                        ) : (
                            <Page403 />
                        )}
                    </Switch>
                </Container>
            )}
        </Protected>
    );
};

export default PageDashboard;
