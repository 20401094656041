import React, { useContext, useEffect, useState } from 'react';
import { Container, Grid, makeStyles, CircularProgress, Typography } from '@material-ui/core';
import { useParams } from 'react-router';
import Referrer from '../components/Referrer/Referrer';
import MuiAlert from '@material-ui/lab/Alert';
import SurveyService from '../services/SurveyService';
import AuthContext from '../context/AuthContext';
import Content from '../components/Content/Content';
import Result from '../components/Result/Result';

function Alert(props) {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: 24,
    },
    alert: {
        marginBottom: 24,
    },
}));

const PageResult = () => {
    /**
     * CSS
     */
    const classes = useStyles();

    /**
     * STATES
     */
    const [loading, setLoading] = useState(true);
    const [questions, setQuestions] = useState([]);
    const [survey, setSurvey] = useState({
        title: '',
        desciption: '',
    });
    const [error, setError] = useState(false);
    const [postError, setPostError] = useState(false);
    const { auth } = useContext(AuthContext);

    /**
     * PARAMS
     */
    const { id } = useParams();

    /**
     * HOOKS
     */
    useEffect(() => {
        async function fetch() {
            const survey = await SurveyService.getById(id, auth.token);
            if (!survey) {
                setError(true);
                setLoading(false);
                return;
            }
            const questions = await SurveyService.getResults(id, auth.token);
            if (!questions) {
                setError(true);
                setLoading(false);
                return;
            }

            setSurvey(survey);
            setQuestions(questions);
            setLoading(false);
        }

        if (auth) {
            fetch();
        }
        // eslint-disable-next-line
    }, []);


    return (
        <Referrer>
            <Container maxWidth='lg' className={classes.container}>
                {loading && (
                    <Grid container spacing={2} justify='center'>
                        <Grid item>
                            <CircularProgress />
                        </Grid>
                    </Grid>
                )}
                {error && (
                    <Grid item xs={12}>
                        <Alert severity='error'>
                            Diese Umfrage ist nicht verfügbar, oder Sie sind nicht an der Teilnahme berechtigt!
                        </Alert>
                    </Grid>
                )}
                {postError && (
                    <Grid item xs={12}>
                        <Alert severity='error'>
                            Es hab einen Fehler bei der Übermittlung der Daten. Bitte versuchen Sie es später erneut.
                        </Alert>
                    </Grid>
                )}
                {!loading && !error && !postError && (
                    <Content title={survey.title}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography>{survey.description}</Typography>
                                </Grid>
                                {questions.map((question, index) => (
                                    <Result key={index} question={question} />
                                ))}
                            </Grid>
                    </Content>
                )}
            </Container>
        </Referrer>
    );
};

export default PageResult;
