import React, { useContext, useEffect, useState } from 'react';
import Header from '../parts/Header';
import { Container, Grid, makeStyles, CircularProgress, Typography } from '@material-ui/core';
import { useHistory, useLocation, useParams } from 'react-router';
import Referrer from '../components/Referrer/Referrer';
import MuiAlert from '@material-ui/lab/Alert';
import SurveyService from '../services/SurveyService';
import AuthContext from '../context/AuthContext';
import Content from '../components/Content/Content';
import TopNavigation from '../parts/TopNavigation';
import Button from '../components/Button/Button';
import { Link } from 'react-router-dom';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

function Alert(props) {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: 24,
    },
    alert: {
        marginBottom: 24,
    },
}));

const PageSurveyFinished = () => {
    /**
     * CSS
     */
    const classes = useStyles();

    /**
     * STATES
     */
    const [loading, setLoading] = useState(true);
    const [questions, setQuestions] = useState([]);
    const [survey, setSurvey] = useState({
        title: '',
        desciption: '',
    });
    const [error, setError] = useState(false);
    const [postError, setPostError] = useState(false);
    const { auth } = useContext(AuthContext);
    const [answers, setAnswers] = useState({});
    const history = useHistory();
    const search = useLocation().search;
    const uid = new URLSearchParams(search).get('uid');

    /**
     * CONTROLS
     */
    const setAnswer = (id, values) => {
        setAnswers({ ...answers, [id]: values });
    };

    /**
     * PARAMS
     */
    const { id } = useParams();

    /**
     * HOOKS
     */
    useEffect(() => {
        async function fetch() {
            const survey = await SurveyService.getById(id, auth.token);
            if (!survey) {
                setError(true);
                setLoading(false);
                return;
            }
            const questions = await SurveyService.getQuestions(id, auth.token);
            if (!questions) {
                setError(true);
                setLoading(false);
                return;
            }

            setSurvey(survey);
            setQuestions(questions);
            setLoading(false);
        }

        if (auth) {
            fetch();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <Referrer>
            <header>
                <Container maxWidth='lg'>
                    <Header />
                    {auth && (auth.role === 'ROLE_ADMIN' || auth.role === 'ROLE_LEADER') && <TopNavigation />}
                </Container>
            </header>
            <Container maxWidth='lg' className={classes.container}>
                {loading && (
                    <Grid container spacing={2} justify='center'>
                        <Grid item>
                            <CircularProgress />
                        </Grid>
                    </Grid>
                )}
                {error && (
                    <Grid item xs={12}>
                        <Alert severity='error'>
                            Diese Umfrage ist nicht verfügbar, oder Sie sind nicht an der Teilnahme berechtigt!
                        </Alert>
                    </Grid>
                )}
                {postError && (
                    <Grid item xs={12}>
                        <Alert severity='error'>
                            Es hab einen Fehler bei der Übermittlung der Daten. Bitte versuchen Sie es später erneut.
                        </Alert>
                    </Grid>
                )}
                {!loading && !error && !postError && (
                    <Content title={survey.title}>
                        <Grid container justify='center'>
                            <Grid item>
                                <Typography>
                                    Vielen Dank für Ihre Teilnahme. Sie können sich Ihren Fragebogen als PDF
                                    herunterladen.
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid className={classes.container} container justify='center'>
                            <Grid item>
                                <Button
                                    component={Link}
                                    to={`/api/pdf/${id}/${uid}?token=${auth.token}`}
                                    target='_blank'
                                    download
                                    variant='contained'
                                    color='primary'
                                    startIcon={<PictureAsPdfIcon />}
                                >
                                    PDF herunterladen
                                </Button>
                            </Grid>
                        </Grid>
                    </Content>
                )}
            </Container>
        </Referrer>
    );
};

export default PageSurveyFinished;
