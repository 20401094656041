import React from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import { Grid } from '@material-ui/core';

function Alert(props) {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const Page403 = () => {
    return (
        <Grid container justify='center'>
            <Grid item>
                <Alert severity='error'>Sie sind nicht berechtigt auf diesen Bereich zuzugreifen!</Alert>
            </Grid>
        </Grid>
    );
};

export default Page403;
