import {
    Grid,
    TextField,
    CircularProgress,
    IconButton,
    Fade,
    Typography,
    Modal,
    Backdrop,
    Button as MButton,
} from '@material-ui/core';
import React, { useContext, useState } from 'react';
import Content from '../components/Content/Content';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../components/Button/Button';
import AddIcon from '@material-ui/icons/Add';
import SortableTree from 'react-sortable-tree';
import SendIcon from '@material-ui/icons/Send';
import DeleteIcon from '@material-ui/icons/Delete';
import SectionService from '../services/SectionService';
import AuthContext from '../context/AuthContext';
import MuiAlert from '@material-ui/lab/Alert';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { Fragment } from 'react';
import axios from 'axios';

function Alert(props) {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: 16,
        fontWeight: 600,
    },
    container: {
        backgroundColor: '#eee',
        padding: 6,
    },
    grow: {
        flexGrow: 1,
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #39AC39',
        borderRadius: 5,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

const SectionForm = () => {
    /**
     * CSS
     */
    const classes = useStyles();

    /**
     * STATES
     */
    const [state, setState] = useState([]);
    const [name, setName] = useState('');
    const [error, setError] = useState(false);
    const { auth } = useContext(AuthContext);
    const [success, setSuccess] = useState(false);
    const [saveError, setSaveError] = useState(false);
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [fetchError, setFetchError] = useState(false);
    const [open, setOpen] = useState(false);
    const [toDelete, setToDelete] = useState(null);

    /**
     * CONTROLS
     */
    const addSection = () => {
        if (name === '') {
            setError(true);
        } else {
            setError(false);
            setState([...state, { title: name, children: [] }]);
            setName('');
        }
    };

    async function fetch() {
        setLoading(true);
        const response = await SectionService.get(auth.token);
        if (response) {
            setLoading(false);
            setState(response);
        } else {
            setLoading(false);
            setFetchError(true);
        }
    }

    /**
     * HOOKS
     */
    useEffect(() => {
        fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const change = (e) => {
        setName(e.target.value);
    };

    const deleteSectionModal = (data) => {
        console.log(data);
        setOpen(true);
        setToDelete(data.node.id);
    };

    const closeModal = (e) => {
        setOpen(false);
    };

    const deleteSection = async (e) => {
        try {
            await axios.delete(`/api/sections/${toDelete}`, {
                headers: {
                    'x-auth-token': auth.token,
                },
            });
            fetch();
            setOpen(false);
        } catch (err) {
            console.log(err);
        }
    };
    /**
     * SUBMIT
     */
    const submit = async () => {
        const result = await SectionService.post(state, auth.token);
        if (result) {
            setSuccess(true);
            history.push('/');
        } else {
            setSaveError(true);
        }
    };

    return (
        <Content title='Bereiche bearbeiten'>
            <Grid container spacing={2}>
                {loading && (
                    <Grid container spacing={2} justify='center'>
                        <Grid item>
                            <CircularProgress />
                        </Grid>
                    </Grid>
                )}
                {fetchError && (
                    <Grid item xs={12}>
                        <Alert severity='error'>Fehler beim Abrufen der Daten!</Alert>
                    </Grid>
                )}
                {!loading && !fetchError && (
                    <Fragment>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item className={classes.grow}>
                                    <TextField
                                        fullWidth
                                        error={error}
                                        helperText={error && 'Der Bereichsname darf nicht leer sein'}
                                        onChange={change}
                                        value={name}
                                        label='Bereichsname*'
                                    />
                                </Grid>
                                <Grid item>
                                    <Button
                                        onClick={addSection}
                                        variant='contained'
                                        color='primary'
                                        startIcon={<AddIcon />}
                                    >
                                        Bereich hinzufügen
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <SortableTree
                                isVirtualized={false}
                                treeData={state}
                                onChange={(treeData) => setState(treeData)}
                                generateNodeProps={(rowInfo) => ({
                                    buttons: [
                                        <IconButton
                                            onClick={(e) => deleteSectionModal(rowInfo)}
                                            size='small'
                                            color='secondary'
                                            inputProps={{ 'aria-label': 'delete' }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>,
                                    ],
                                })}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {success && <Alert severity='success'>Änderungen gespeichert</Alert>}
                            {saveError && <Alert severity='error'>Fehler beim Speichern der Daten!</Alert>}
                        </Grid>
                        <Grid item xs={12}>
                            <Button onClick={submit} variant='contained' color='primary' endIcon={<SendIcon />}>
                                Speichern
                            </Button>
                        </Grid>
                    </Fragment>
                )}
            </Grid>
            <Modal
                aria-labelledby='delete-modal-title'
                aria-describedby='delete-modal-description'
                open={open}
                closeAfterTransition
                className={classes.modal}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <Typography component='h4' variant='h4' id='transition-modal-title'>
                            Löschen?
                        </Typography>
                        <div id='transition-modal-description'>
                            <p>
                                <Typography>
                                    Möchten Sie den Bereich wirklich löschen? Dieser Vorgang kann nicht rückgängig
                                    gemacht werden.
                                </Typography>
                            </p>
                            <Grid container spacing={2} justify='space-between'>
                                <Grid item>
                                    <MButton onClick={closeModal} variant='outlined' color='primary'>
                                        Abbrechen
                                    </MButton>
                                </Grid>
                                <Grid item>
                                    <MButton onClick={deleteSection} variant='outlined' color='secondary'>
                                        Löschen
                                    </MButton>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </Content>
    );
};

export default SectionForm;
