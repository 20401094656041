import { Grid, makeStyles, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import AddIcon from '@material-ui/icons/CloudUpload';
import Button from '../Button/Button';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: 24,
    },
}));

const UploadQuestion = ({ question, index, onUpdate }) => {
    /**
     * CSS
     */
    const classes = useStyles();

    /**
     * state
     */
    const [value, setValue] = useState('');

    const changeText = (e) => {
        onUpdate(index, { ...question, text: e.target.value });
    };

    return (
        <Grid container spacing={2} className={classes.container}>
            <Grid item xs={12}>
                <TextField required onChange={changeText} value={question.text || ''} fullWidth label='Frage' />
            </Grid>
            <Grid item xs={12}>
                <Grid container justify='center'>
                    <Grid item>
                        <Button variant='contained' color='primary' startIcon={<AddIcon />}>
                            Bild hochladen
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default UploadQuestion;
