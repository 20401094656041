import { Grid, FormControlLabel, Radio, Checkbox } from '@material-ui/core';
import React, { Fragment } from 'react';
import { useState } from 'react';

const Multiple = ({ question, setAnswer }) => {
    /**
     * STATE
     */
    const [value, setValue] = useState([]);

    /**
     * CONTROLS
     */
    const change = (e) => {
        if (e.target.checked && !value.includes(e.target.value)) {
            let newValues = [...value];
            newValues.push(e.target.value);
            setValue(newValues);
            setAnswer(question.id, newValues);
        } else {
            let newValues = [...value];
            newValues = newValues.filter((item) => item !== e.target.value);
            setValue(newValues);
            setAnswer(question.id, newValues);
        }
    };

    return (
        <Fragment>
            {question.answers.map((choice, index) => (
                <Grid key={index} item xs={12} sm={12}>
                    <FormControlLabel
                        onChange={change}
                        control={<Checkbox />}
                        label={choice.text}
                        labelPlacement='end'
                        value={choice.text}
                        checked={value.includes(choice.text)}
                    />
                </Grid>
            ))}
        </Fragment>
    );
};

export default Multiple;
