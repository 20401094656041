import { Grid, makeStyles, TextField } from '@material-ui/core';
import React, { useState } from 'react';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: 24,
    },
}));

const TextFieldQuestion = ({ question, index, onUpdate }) => {
    /**
     * CSS
     */
    const classes = useStyles();

    /**
     * state
     */
    const [value, setValue] = useState('');

    /**
     * CONTROLS
     */
    const change = (e) => {
        setValue(e.target.value);
    };

    const changeText = (e) => {
        onUpdate(index, { ...question, text: e.target.value });
    };

    return (
        <Grid container spacing={2} className={classes.container}>
            <Grid item xs={12}>
                <TextField required onChange={changeText} value={question.text || ''} fullWidth label='Frage' />
            </Grid>
            <Grid item xs={12}>
                <TextField value={value} onChange={change} fullWidth label='Antwort'  />
            </Grid>
        </Grid>
    );
};

export default TextFieldQuestion;
