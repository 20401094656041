import React, { Fragment, useContext, useEffect, useState } from 'react';
import Header from '../parts/Header';
import { Container, Grid, makeStyles, CircularProgress, Typography } from '@material-ui/core';
import { useHistory, useParams } from 'react-router';
import Referrer from '../components/Referrer/Referrer';
import MuiAlert from '@material-ui/lab/Alert';
import SurveyService from '../services/SurveyService';
import AuthContext from '../context/AuthContext';
import Content from '../components/Content/Content';
import Choice from '../components/Choice/Choice';
import TopNavigation from '../parts/TopNavigation';
import Button from '../components/Button/Button';
import SendIcon from '@material-ui/icons/Send';
import axios from 'axios';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

function Alert(props) {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: 24,
    },
    alert: {
        marginBottom: 24,
    },
}));

const PageView = () => {
    /**
     * CSS
     */
    const classes = useStyles();

    /**
     * STATES
     */
    const [loading, setLoading] = useState(true);
    const [survey, setSurvey] = useState({
        title: '',
        desciption: '',
    });
    const [error, setError] = useState(false);
    const { auth } = useContext(AuthContext);
    const [answers, setAnswers] = useState({});
    const [files, setFiles] = useState({});

    /**
     * PARAMS
     */
    const { id, uid } = useParams();

    /**
     * HOOKS
     */
    useEffect(() => {
        async function fetch() {
            const survey = await SurveyService.getByIdWithAnswers(id, auth.token, uid);
            if (!survey) {
                setError(true);
                setLoading(false);
                return;
            }

            setSurvey(survey);
            setLoading(false);
        }

        if (auth) {
            fetch();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <Content title='Umfrage Ansicht'>
            <Container maxWidth='lg' className={classes.container}>
                {loading && (
                    <Grid container spacing={2} justify='center'>
                        <Grid item>
                            <CircularProgress />
                        </Grid>
                    </Grid>
                )}
                {error && (
                    <Grid item xs={12}>
                        <Alert severity='error'>
                            Diese Umfrage ist nicht verfügbar, oder Sie sind nicht an der Teilnahme berechtigt!
                        </Alert>
                    </Grid>
                )}
                {!loading && !error && (
                    <Content title={survey.title}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography>{survey.description}</Typography>
                            </Grid>
                            {survey.questions.map((question, index) => (
                                <Fragment>
                                    <Grid key={index} item xs={12} className={classes.container}>
                                        <Typography variant='h6' component='h6'>
                                            {question.text}
                                        </Typography>
                                    </Grid>
                                    {question.answers.map((answer, index) => (
                                        <Grid key={index} item xs={12} className={classes.container}>
                                            {answer.isFile ? (
                                                <img
                                                    width='600'
                                                    height='auto'
                                                    src={`data:${answer.mime};base64,${answer.text}`}
                                                />
                                            ) : (
                                                <Typography variant='h6' component='h6'>
                                                    {answer.text}
                                                </Typography>
                                            )}
                                        </Grid>
                                    ))}
                                </Fragment>
                            ))}
                            <Grid item xs={12} className={classes.container}>
                                <Button
                                    component={Link}
                                    to={`/umfrage/details/${id}`}
                                    variant='contained'
                                    color='primary'
                                    startIcon={<ArrowBackIcon />}
                                >
                                    Zurück
                                </Button>
                            </Grid>
                        </Grid>
                    </Content>
                )}
            </Container>
        </Content>
    );
};

export default PageView;
