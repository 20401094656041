import React, { Fragment } from 'react';
import { Typography, Grid, makeStyles } from '@material-ui/core';
import { BarChart, Bar, XAxis, YAxis, Tooltip, } from 'recharts';

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: 24,
    },
}));
const Result = ({ question }) => {
    /**
     * CSS
     */
    const classes = useStyles();

    return (
        <Fragment>
            <Grid item xs={12} className={classes.container}>
                <Typography variant='h6' component='h6'>
                    {question.text}
                </Typography>
            </Grid>
            {question.type === 'yesno' && (
                <Grid item xs={12} className={classes.container}>
                    <BarChart width={600} height={300} data={question.answers}>
                        <XAxis dataKey='text' />
                        <YAxis />
                        <Tooltip />
                        <Bar dataKey='count' fill='#8884d8' />
                    </BarChart>
                </Grid>
            )}
        </Fragment>
    );
};

export default Result;
