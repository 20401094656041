import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Link,
    CircularProgress,
    Tooltip,
    IconButton,
    Fade,
    Typography,
    Grid,
    Modal,
    Backdrop,
    Button as MButton,
} from '@material-ui/core';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AuthContext from '../../context/AuthContext';
import { Link as RLink } from 'react-router-dom';
import UserService from '../../services/UserService';
import { Delete as DeleteIcon } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    table: {
        width: '100%',
    },
    title: {
        fontSize: 16,
        fontWeight: 600,
    },
    container: {
        backgroundColor: '#eee',
        padding: 6,
    },
    grow: {
        flexGrow: 1,
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #39AC39',
        borderRadius: 5,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

const UserList = () => {
    /**
     * CSS
     */
    const classes = useStyles();

    /**
     * STATES
     */
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const { auth } = useContext(AuthContext);
    const [open, setOpen] = useState(false);
    const [toDelete, setToDelete] = useState(0);

    async function fetch() {
        const data = await UserService.getAll(auth.token);
        if (data) {
            setUsers(data);
            setLoading(false);
        } else {
            setLoading(false);
            setError(true);
        }
    }

    /**
     * HOOKS
     */
    useEffect(() => {
        fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const showModal = (userId) => {
        setToDelete(userId);
        setOpen(true);
    };

    const closeModal = () => {
        setOpen(false);
    };

    const deleteUser = async () => {
        await UserService.delete(toDelete, auth.token);
        fetch();
        setOpen(false);
    };

    return (
        <Fragment>
            <TableContainer>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Benutzername</TableCell>
                            <TableCell>Rolle</TableCell>
                            <TableCell>Bereich</TableCell>
                            <TableCell align='right'>Aktionen</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading && (
                            <TableRow>
                                <TableCell align='center' colSpan={4}>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        )}
                        {error && (
                            <TableRow>
                                <TableCell align='center' colSpan={4}>
                                    Fehler beim Abrufen der Daten
                                </TableCell>
                            </TableRow>
                        )}
                        {users.map((user, index) => (
                            <TableRow key={index}>
                                <TableCell></TableCell>
                                <TableCell>
                                    <Link component={RLink} to={`/benutzer/bearbeiten/${user.id}`}>
                                        {user.username}
                                    </Link>
                                </TableCell>
                                <TableCell>{UserService.role(user.role)}</TableCell>
                                <TableCell>{user.section && user.section}</TableCell>
                                <TableCell align='right'>
                                    <Tooltip title='Löschen'>
                                        <IconButton
                                            onClick={(e) => showModal(user.id)}
                                            size='small'
                                            aria-label='loeschen'
                                            color='secondary'
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Modal
                aria-labelledby='delete-modal-title'
                aria-describedby='delete-modal-description'
                open={open}
                closeAfterTransition
                className={classes.modal}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <Typography component='h4' variant='h4' id='transition-modal-title'>
                            Löschen?
                        </Typography>
                        <div id='transition-modal-description'>
                            <p>
                                <Typography>
                                    Möchten Sie den Benutzer wirklich löschen? Dieser Vorgang kann nicht rückgängig
                                    gemacht werden.
                                </Typography>
                            </p>
                            <Grid container spacing={2} justify='space-between'>
                                <Grid item>
                                    <MButton onClick={closeModal} variant='outlined' color='primary'>
                                        Abbrechen
                                    </MButton>
                                </Grid>
                                <Grid item>
                                    <MButton onClick={deleteUser} variant='outlined' color='secondary'>
                                        Löschen
                                    </MButton>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </Fragment>
    );
};

export default UserList;
