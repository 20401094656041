import { Grid, Link as MLink, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import logo from '../assets/img/logo.png';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import Button from '../components/Button/Button';

const useStyles = makeStyles((theme) => ({
    logo: {
        height: 50,
    },
    mg: {
        color: '#fff',
    },
    top: {
        marginTop: 8,
    },
}));
const Header = () => {
    /**
     * CSS
     */
    const classes = useStyles();

    /**
     * CONTEXT
     */

    const { auth } = useContext(AuthContext);

    return (
        <Grid container className={classes.top} justify='space-between'>
            <Grid item>
                <img className={classes.logo} src={logo} alt='Oekoclean Logo' />
            </Grid>
            <Grid item>
                <Typography>
                    <Button
                        className={classes.mg}
                        component={Link}
                        target='_blank'
                        to={{
                            pathname:
                                auth.link == null || auth.link == ''
                                    ? 'https://Oeko:Clean@www.oekoclean.club'
                                    : auth.link,
                        }}
                        variant='contained'
                        color='primary'
                    >
                        Zum Informations-/Download Center
                    </Button>
                </Typography>
            </Grid>
            <Grid item>
                <Typography>
                    <MLink component={Link} to='/logout'>
                        Abmelden ({auth.username})
                    </MLink>
                </Typography>
            </Grid>
        </Grid>
    );
};

export default Header;
