import React from 'react';
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import logo from '../assets/img/logo.png';
import LoginForm from '../forms/LoginForm';
import { useLocation } from 'react-router-dom';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: 24,
    },
    padding: {
        padding: 32,
    },
    subtext: {
        fontSize: 14,
    },
    heading: {
        color: '#39AC39',
    },
    margin: {
        marginTop: 48,
    },
    logo: {
        textAlign: 'center',
    },
}));

const PageLogin = () => {
    const query = useQuery();
    const classes = useStyles();

    return (
        <Grid className={classes.container} container spacing={2} justify='center'>
            <Grid item xs={12} sm={6} md={6} lg={4}>
                <Paper elevation={1}>
                    <Grid container spacing={2} className={classes.padding}>
                        <Grid item xs={12} className={classes.logo}>
                            <img
                                style={{ margin: '0 auto' }}
                                height='50'
                                width='auto'
                                src={logo}
                                alt='Oekoclean Logo'
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.heading} component='h4' variant='h4'>
                                Anmeldung
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {query.get('referrer') ? (
                                <Typography component='p' className={classes.subtext}>
                                   
                                </Typography>
                            ) : (
                                <Typography component='p' className={classes.subtext}>
                                    Um Umfragen und Auswertungen zu erstellen, müssen Sie angemeldet sein.
                                </Typography>
                            )}
                        </Grid>
                        <Grid className={classes.margin} item xs={12}>
                            <LoginForm />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default PageLogin;
