import axios from 'axios';

class UserService {
    static role(name) {
        switch (name) {
            case 'ROLE_ADMIN':
                return 'Administrator';
            case 'ROLE_LEADER':
                return 'Leiter';
            default:
                return 'Benutzer';
        }
    }

    static async getAll(token) {
        try {
            const response = await axios.get('/api/users', {
                headers: {
                    'x-auth-token': token,
                },
            });
            return response.data;
        } catch (err) {
            return null;
        }
    }

    static async post(data, token) {
        try {
            await axios.post('/api/users', data, {
                headers: {
                    'x-auth-token': token,
                },
            });
            return true;
        } catch (err) {
            return false;
        }
    }

    static async delete(id, token) {
        try {
            console.log(id);
            await axios.delete(`/api/users/${id}`, {
                headers: {
                    'x-auth-token': token,
                },
            });
            return true;
        } catch (err) {
            return false;
        }
    }
}

export default UserService;
