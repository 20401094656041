import axios from 'axios';

class AuthService {
    static async auth(state) {
        try {
            const response = await axios.post('/api/auth', state);
            return response.data;
        } catch (err) {
            return false;
        }
    }
}

export default AuthService;
