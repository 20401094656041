import React from 'react';
import Content from '../components/Content/Content';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import Button from '../components/Button/Button';
import AddIcon from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';
import UserList from '../components/UserList/UserList';

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: 16,
        fontWeight: 600,
    },
    container: {
        backgroundColor: '#eee',
        padding: 6,
    },
}));

const Users = () => {
    /**
     * CSS
     */
    const classes = useStyles();

    return (
        <Content title='Veraltung von Benutzern'>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid className={classes.container} alignItems='center' container justify='space-between'>
                        <Grid item>
                            <Typography className={classes.title} component='h2' variant='h2'>
                                Ihre Benutzer
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Button
                                component={Link}
                                to='/neuer-benutzer'
                                variant='contained'
                                color='primary'
                                startIcon={<AddIcon />}
                            >
                                Neuen Benutzer erstellen
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <UserList />
                </Grid>
            </Grid>
        </Content>
    );
};

export default Users;
