import React, { Fragment } from 'react';
import { Typography, Grid, makeStyles } from '@material-ui/core';
import Yesno from './Yesno';
import FreeText from './FreeText';
import TextFieldChoice from './TextFieldChoice';
import Free from './Free';
import Multiple from './Multiple';
import Upload from './Upload';

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: 24,
    },
}));
const Choice = ({ question, setAnswer, setFiles }) => {
    /**
     * CSS
     */
    const classes = useStyles();

    return (
        <Fragment>
            <Grid item xs={12} className={classes.container}>
                <Typography variant='h6' component='h6'>
                    {question.text}
                </Typography>
            </Grid>
            {question.type === 'yesno' && <Yesno question={question} setAnswer={setAnswer} />}
            {question.type === 'freetext' && <FreeText question={question} setAnswer={setAnswer} />}
            {question.type === 'textfield' && <TextFieldChoice question={question} setAnswer={setAnswer} />}
            {question.type === 'free' && question.multiple === 0 && <Free question={question} setAnswer={setAnswer} />}
            {question.type === 'free' && question.multiple === 1 && <Multiple question={question} setAnswer={setAnswer} />}
            {question.type === 'upload' && <Upload setFiles={setFiles} question={question} />}
        </Fragment>
    );
};

export default Choice;
