import { FormControl, Grid, InputLabel, makeStyles, MenuItem, Paper, Select, IconButton } from '@material-ui/core';
import React from 'react';
import RadioQuestion from './RadioQuestion';
import DeleteIcon from '@material-ui/icons/Delete';
import FreeTextQuestion from './FreeTextQuestion';
import FreeQuestion from './FreeQuestion';
import TextFieldQuestion from './TextFieldQuestion';
import UploadQuestion from './UploadQuestion';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: 24,
    },
}));

const Question = ({ question, index, onUpdate, onDelete }) => {
    /**
     * CSS
     */
    const classes = useStyles();

    /**
     * CONTROLS
     */
    const change = (e) => {
        onUpdate(index, { ...question, type: e.target.value });
    };

    return (
        <Paper className={classes.paper}>
            <Grid container spacing={2} justify='flex-end'>
                <Grid item>
                    <IconButton onClick={(e) => onDelete(index)} size='small' aria-label='löschen' color='secondary'>
                        <DeleteIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <FormControl fullWidth>
                <InputLabel id={`question-${index}-select-label`}>Wählen Sie die Art der Frage</InputLabel>
                <Select
                    labelId={`question-${index}-select-label`}
                    name={`question-${index}-select`}
                    id={`question-${index}-select`}
                    value={question.type}
                    onChange={change}
                    disabled={question.id ? true : false}
                >
                    <MenuItem value='yesno'>Ja / Nein - Frage</MenuItem>
                    <MenuItem value='textfield'>Textfeld</MenuItem>
                    <MenuItem value='freetext'>Freitext</MenuItem>
                    <MenuItem value='free'>Freie Antworten</MenuItem>
                    <MenuItem value='upload'>Bild hochladen</MenuItem>
                </Select>
            </FormControl>
            {question.type === 'yesno' && <RadioQuestion question={question} onUpdate={onUpdate} index={index} />}
            {question.type === 'freetext' && <FreeTextQuestion question={question} onUpdate={onUpdate} index={index} />}
            {question.type === 'free' && <FreeQuestion question={question} onUpdate={onUpdate} index={index} />}
            {question.type === 'textfield' && (
                <TextFieldQuestion question={question} onUpdate={onUpdate} index={index} />
            )}
            {question.type === 'upload' && <UploadQuestion question={question} onUpdate={onUpdate} index={index} />}
        </Paper>
    );
};

export default Question;
