import React, { useContext, useEffect } from 'react';
import { Redirect } from 'react-router';
import AuthContext from '../../context/AuthContext';

const Logout = () => {
    const { setAuth } = useContext(AuthContext);

    useEffect(() => {
        setAuth(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Redirect to='/login' />;
};

export default Logout;
