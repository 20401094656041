import { Grid, TextField, CircularProgress, Typography, Checkbox } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import Button from '../components/Button/Button';
import Content from '../components/Content/Content';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useHistory } from 'react-router-dom';
import SurveyService from '../services/SurveyService';
import AuthContext from '../context/AuthContext';
import SectionService from '../services/SectionService';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import SortableTree from 'react-sortable-tree';
import { useParams } from 'react-router-dom';

function Alert(props) {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: 18,
        fontWeight: 600,
    },
    margin: {
        marginTop: 24,
    },
}));

const SurveyForm = () => {
    /**
     * CSS
     */
    const classes = useStyles();

    /**
     * STATE
     */
    const [state, setState] = useState({
        title: '',
        description: '',
        section: 0,
    });
    const history = useHistory();
    const { auth } = useContext(AuthContext);
    const [sections, setSections] = useState([]);
    const [fetchError, setFetchError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [sectionError, setSectionError] = useState(false);
    const { id } = useParams();

    /**
     * HOOKS
     */
    useEffect(() => {
        async function fetch() {
            const response = await SectionService.get(auth.token);
            if (response) {
                setSections(response);
                if (id) {
                    fetchSurvey();
                } else {
                    setLoading(false);
                }
            } else {
                setFetchError(true);
                setLoading(false);
            }
        }
        async function fetchSurvey() {
            const response = await SurveyService.getById(id, auth.token);
            if (response) {
                console.log(response);
                setState({
                    title: response.title,
                    description: response.description,
                    section: response.section_id,
                });
                setLoading(false);
            } else {
                setFetchError(true);
                setLoading(false);
            }
        }
        fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * CONTROLS
     */
    const change = (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    };

    const selectSection = ({ node, path, treeIndex, lowerSiblingCounts: _lowerSiblingCounts }, e) => {
        setState({ ...state, section: node.id });
    };

    /**
     * SUBMIT
     */
    const submit = async (e) => {
        e.preventDefault();

        if (state.section !== 0) {
            if (id) {
                const response = await SurveyService.duplicate(id, state, auth.token);
                if (response) {
                    history.push(`/`);
                }
            } else {
                const response = await SurveyService.post(state, auth.token);
                if (response) {
                    history.push(`/umfrage/bearbeiten/${response.id}`);
                }
            }
        } else {
            setSectionError(true);
        }

        return false;
    };

    return (
        <Content title={id ? 'Umfrage duplizieren' : 'Neue Umfrage erstellen'}>
            {loading && (
                <Grid container justify='center'>
                    <Grid item>
                        <CircularProgress />
                    </Grid>
                </Grid>
            )}
            {fetchError && (
                <Grid item xs={12}>
                    <Alert severity='error'>Fehler beim Abrufen der Daten!</Alert>
                </Grid>
            )}
            {!loading && !fetchError && (
                <form onSubmit={submit}>
                    <Grid container spacing={2}>
                        <Grid className={classes.margin} item xs={12}>
                            <Typography variant='h1' component='h1' className={classes.title}>
                                Bereich wählen
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <SortableTree
                                isVirtualized={false}
                                treeData={sections}
                                canDrag={false}
                                generateNodeProps={(rowInfo) => ({
                                    buttons: [
                                        <Checkbox
                                            className='section-select'
                                            color='primary'
                                            checked={state.section === rowInfo.node.id}
                                            onClick={(e) => selectSection(rowInfo, e)}
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />,
                                    ],
                                })}
                            />
                        </Grid>
                        {sectionError && (
                            <Grid item xs={12}>
                                <Alert severity='error'>Es muss ein Bereich gewählt werden.</Alert>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <TextField
                                value={state.title}
                                onChange={change}
                                fullWidth
                                name='title'
                                id='title'
                                label='Umfragetitel'
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                helperText='Dieser Text wird als erstes angezeigt, wenn Ihre Umfrage geöffnet wird.
                                        Lassen Sie es leer, falls Ihre Teilnehmer gleich zu den Fragen gelangen sollen.'
                                multiline
                                rows={10}
                                fullWidth
                                name='description'
                                id='description'
                                label='Begrüssung / Einleitung / Beschreibung'
                                value={state.description}
                                onChange={change}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button type='submit' variant='contained' color='primary' endIcon={<ChevronRightIcon />}>
                                Weiter
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            )}
        </Content>
    );
};

export default SurveyForm;
