import {
    FormControlLabel,
    Grid,
    makeStyles,
    Radio,
    TextField,
    Checkbox,
    Modal,
    Backdrop,
    Fade,
    Typography,
    Button as MButton,
    Tooltip,
    IconButton,
} from '@material-ui/core';
import React, { Fragment, useContext, useState } from 'react';
import Button from '../Button/Button';
import AddIcon from '@material-ui/icons/Add';
import { Delete as DeleteIcon } from '@material-ui/icons';
import axios from 'axios';
import AuthContext from '../../context/AuthContext';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: 24,
    },
    margin: {
        marginTop: 24,
    },
    title: {
        fontSize: 16,
        fontWeight: 600,
    },
    container2: {
        backgroundColor: '#eee',
        padding: 6,
    },
    grow: {
        flexGrow: 1,
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #39AC39',
        borderRadius: 5,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

const FreeQuestion = ({ question, index, onUpdate }) => {
    /**
     * CSS
     */
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [toDelete, setToDelete] = useState(-1);

    const { auth } = useContext(AuthContext);

    const changeText = (e) => {
        onUpdate(index, { ...question, text: e.target.value });
    };

    const changeType = (e) => {
        onUpdate(index, { ...question, multiple: e.target.checked });
    };

    const changeAnswer = (e, i) => {
        let newAnswers = [...question.answers];
        newAnswers[i].text = e.target.value;
        onUpdate(index, { ...question, answers: newAnswers });
    };

    const addAnswer = (i) => {
        let newAnswers = [...question.answers];
        if (i < 0) {
            newAnswers.push({
                text: '',
            });
        } else {
            newAnswers.splice(i, 0, {
                text: '',
            });
        }
        onUpdate(index, { ...question, answers: newAnswers });
    };

    const closeModal = (e) => {
        setOpen(false);
    };

    const deleteAnswer = async () => {
        setOpen(false);
        let newAnswers = [...question.answers];
        if (question.answers[toDelete].id !== undefined) {
            await axios.delete(`/api/answers/${question.answers[toDelete].id}`, {
                headers: {
                    'x-auth-token': auth.token,
                },
            });
        }
        newAnswers.splice(toDelete, 1);
        onUpdate(index, { ...question, answers: newAnswers });
    };

    const deleteAnswerModal = (index) => {
        setToDelete(index);
        setOpen(true);
    };

    return (
        <Fragment>
            <Grid container spacing={2} className={classes.container}>
                <Grid item xs={12}>
                    <TextField required onChange={changeText} value={question.text || ''} fullWidth label='Frage' />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                color='primary'
                                checked={question.multiple ? question.multiple : false}
                                onChange={changeType}
                            />
                        }
                        label={'Mehrfachauswahl'}
                        labelPlacement='end'
                    />
                </Grid>
                {question.answers.map((answer, index) => (
                    <Fragment>
                        <Grid className={classes.margin} item xs={12}>
                            <Grid container justify='center'>
                                <Grid item>
                                    <Button
                                        onClick={(e) => addAnswer(index)}
                                        variant='contained'
                                        color='primary'
                                        startIcon={<AddIcon />}
                                    >
                                        Antwort hinzufügen
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.grow}>
                            <TextField
                                required
                                onChange={(e) => changeAnswer(e, index)}
                                value={answer.text}
                                fullWidth
                                label='Antwort'
                            />
                        </Grid>
                        <Grid item>
                            <Tooltip title='Löschen'>
                                <IconButton
                                    onClick={(e) => deleteAnswerModal(index)}
                                    size='small'
                                    color='secondary'
                                    aria-label='loeschen'
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Fragment>
                ))}
                <Grid item xs={12}>
                    <Grid container justify='center'>
                        <Grid item>
                            <Button
                                onClick={(e) => addAnswer(-1)}
                                variant='contained'
                                color='primary'
                                startIcon={<AddIcon />}
                            >
                                Antwort hinzufügen
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Modal
                aria-labelledby='delete-modal-title'
                aria-describedby='delete-modal-description'
                open={open}
                closeAfterTransition
                className={classes.modal}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <Typography component='h4' variant='h4' id='transition-modal-title'>
                            Löschen?
                        </Typography>
                        <div id='transition-modal-description'>
                            <p>
                                <Typography>
                                    Möchten Sie die Antwort wirklich löschen? Dieser Vorgang kann nicht rückgängig
                                    gemacht werden.
                                </Typography>
                            </p>
                            <Grid container spacing={2} justify='space-between'>
                                <Grid item>
                                    <MButton onClick={closeModal} variant='outlined' color='primary'>
                                        Abbrechen
                                    </MButton>
                                </Grid>
                                <Grid item>
                                    <MButton onClick={deleteAnswer} variant='outlined' color='secondary'>
                                        Löschen
                                    </MButton>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </Fragment>
    );
};

export default FreeQuestion;
