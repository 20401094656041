import { Grid, FormControlLabel, Radio } from '@material-ui/core';
import React, { Fragment } from 'react';
import { useState } from 'react';

const Yesno = ({ question, setAnswer }) => {
    /**
     * STATE
     */
    const [value, setValue] = useState('');

    /**
     * CONTROLS
     */
    const change = (e) => {
        setValue(e.target.value);
        setAnswer(question.id, [e.target.value]);
    };

    return (
        <Fragment>
            {question.answers.map((choice, index) => (
                <Grid key={index} item xs={12} sm={4}>
                    <FormControlLabel
                        onChange={change}
                        control={<Radio />}
                        label={choice.text}
                        labelPlacement='end'
                        value={choice.text}
                        checked={value === choice.text}
                    />
                </Grid>
            ))}
        </Fragment>
    );
};

export default Yesno;
