import { Grid, IconButton, TextField, Tooltip } from '@material-ui/core';
import React, { Fragment } from 'react';
import { useState } from 'react';
import AddIcon from '@material-ui/icons/CloudUpload';
import Button from '../Button/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Delete as DeleteIcon } from '@material-ui/icons';
import Resizer from "react-image-file-resizer";

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    input: {
        display: 'none',
    },
    grow: {
        flexGrow: 1,
    },
    image: {
        maxWidth: 600,
    },
}));

const Upload = ({ question, setFiles }) => {
    const classes = useStyles();

    /**
     * STATE
     */
    const [value, setValue] = useState('');

    /**
     * CONTROLS
     */
    const change = async (e) => {
        setValue(URL.createObjectURL(e.target.files[0]));
        setFiles(question.id, await resizeFile(e.target.files[0]));
    };

    const deleteImage = (e) => {
        setValue('');
        setFiles(question.id, '');
    };

    return (
        <Fragment>
            <Grid item xs={12}>
                <Grid container justify='center'>
                    <Grid item>
                        <input
                            accept='image/*'
                            className={classes.input}
                            id={`upload-${question.id}`}
                            name={`upload-${question.id}`}
                            type='file'
                            onChange={change}
                        />
                        <label htmlFor={`upload-${question.id}`}>
                            <Button variant='contained' color='primary' component='span' startIcon={<AddIcon />}>
                                Bild hochladen
                            </Button>
                        </label>
                    </Grid>
                </Grid>
            </Grid>
            {value !== '' && (
                <Grid item xs={12}>
                    <Grid container justify='center'>
                        <Grid item>
                            <img className={classes.image} src={value} />
                        </Grid>
                        <Grid item>
                            <Tooltip title='Löschen'>
                                <IconButton onClick={deleteImage} size='small' color='secondary' aria-label='loeschen'>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Fragment>
    );
};

export default Upload;
