import React, { useContext, useState } from 'react';
import PersonIcon from '@material-ui/icons/Person';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import SendIcon from '@material-ui/icons/Send';
import { FormControl, FormHelperText, Grid, Input, InputLabel } from '@material-ui/core';
import Button from '../components/Button/Button';
import AuthService from '../services/AuthService';
import AuthContext from '../context/AuthContext';
import { useHistory, useLocation } from 'react-router-dom';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const LoginForm = () => {
    const history = useHistory();
    const query = useQuery();

    const [state, setState] = useState({
        username: '',
        password: '',
    });
    const [error, setError] = useState(false);

    const { setAuth } = useContext(AuthContext);

    const change = (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
        setError(false);
    };

    const submit = async (e) => {
        e.preventDefault();

        const token = await AuthService.auth(state);
        if (token) {
            setAuth(token);
            history.push(query.get('referrer') || '/');
        } else {
            setError(true);
        }

        return false;
    };

    return (
        <form onSubmit={submit}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor='email'>Benutzername</InputLabel>
                        <Input
                            onChange={change}
                            value={state.username}
                            id='username'
                            name='username'
                            startAdornment={<PersonIcon />}
                            error={error}
                        />
                        {error && <FormHelperText error>Benutzername oder Passwort ist nicht korrekt.</FormHelperText>}
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor='email'>Passwort</InputLabel>
                        <Input
                            onChange={change}
                            value={state.password}
                            type='password'
                            id='password'
                            name='password'
                            startAdornment={<LockOpenIcon />}
                            error={error}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Button type='submit' fullWidth variant='contained' color='primary' endIcon={<SendIcon />}>
                        Anmelden
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default LoginForm;
