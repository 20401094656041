import axios from 'axios';

class QuestionService {
    static async post(survey_id, questions, token) {
        try {
            for (const index in questions) {
                if (questions[index].id === undefined) {
                    await axios.post(
                        '/api/questions',
                        {
                            type: questions[index].type,
                            text: questions[index].text,
                            answers: questions[index].answers,
                            survey_id: survey_id,
                            multiple: questions[index].multiple ? 1 : 0,
                            position: index,
                        },
                        {
                            headers: {
                                'x-auth-token': token,
                            },
                        }
                    );
                } else {
                    await axios.put(
                        `/api/questions/${questions[index].id}`,
                        {
                            type: questions[index].type,
                            text: questions[index].text,
                            answers: questions[index].answers,
                            survey_id: survey_id,
                            multiple: questions[index].multiple ? 1 : 0,
                            position: index,
                        },
                        {
                            headers: {
                                'x-auth-token': token,
                            },
                        }
                    );
                }
            }

            return true;
        } catch (err) {
            console.log(err.response);
            return false;
        }
    }

    static async delete(id, token) {
        try {
            await axios.delete(`/api/questions/${id}`, {
                headers: {
                    'x-auth-token': token,
                },
            });
            return true;
        } catch (err) {
            return false;
        }
    }
}

export default QuestionService;
