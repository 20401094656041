import { Grid, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../components/Button/Button';
import AddIcon from '@material-ui/icons/Add';
import SurveyList from '../components/SurveyList/SurveyList';
import { Link } from 'react-router-dom';
import Content from '../components/Content/Content';
import AuthContext from '../context/AuthContext';

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: 16,
        fontWeight: 600,
    },
    container: {
        backgroundColor: '#eee',
        padding: 6,
    },
}));

const Surveys = () => {
    /**
     * CSS
     */
    const classes = useStyles();

    const { auth } = useContext(AuthContext);

    return (
        <Content title='Verwaltung von Umfragen'>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid className={classes.container} alignItems='center' container justify='space-between'>
                        <Grid item>
                            <Typography className={classes.title} component='h2' variant='h2'>
                                Ihre Umfragen
                            </Typography>
                        </Grid>
                        <Grid item>
                            {auth.role === 'ROLE_ADMIN' && (
                                <Button
                                    component={Link}
                                    to='/neue-umfrage'
                                    variant='contained'
                                    color='primary'
                                    startIcon={<AddIcon />}
                                >
                                    Neue Umfrage erstellen
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <SurveyList />
                </Grid>
            </Grid>
        </Content>
    );
};

export default Surveys;
