import React, { useContext, useEffect, useState } from 'react';
import Header from '../parts/Header';
import { Container, Grid, makeStyles, CircularProgress, Typography } from '@material-ui/core';
import { useHistory, useParams } from 'react-router';
import Referrer from '../components/Referrer/Referrer';
import MuiAlert from '@material-ui/lab/Alert';
import SurveyService from '../services/SurveyService';
import AuthContext from '../context/AuthContext';
import Content from '../components/Content/Content';
import Choice from '../components/Choice/Choice';
import TopNavigation from '../parts/TopNavigation';
import Button from '../components/Button/Button';
import SendIcon from '@material-ui/icons/Send';
import axios from 'axios';

function Alert(props) {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: 24,
    },
    alert: {
        marginBottom: 24,
    },
}));

const PageSurvey = () => {
    /**
     * CSS
     */
    const classes = useStyles();

    /**
     * STATES
     */
    const [loading, setLoading] = useState(true);
    const [questions, setQuestions] = useState([]);
    const [survey, setSurvey] = useState({
        title: '',
        desciption: '',
    });
    const [error, setError] = useState(false);
    const [postError, setPostError] = useState(false);
    const { auth } = useContext(AuthContext);
    const [answers, setAnswers] = useState({});
    const [files, setFiles] = useState({});
    const history = useHistory();

    /**
     * CONTROLS
     */
    const setAnswer = (id, values) => {
        setAnswers({ ...answers, [id]: values });
    };

    const setFile = (id, file) => {
        setFiles({ ...files, [id]: file });
    };

    /**
     * PARAMS
     */
    const { id } = useParams();

    /**
     * HOOKS
     */
    useEffect(() => {
        async function fetch() {
            const survey = await SurveyService.getById(id, auth.token);
            if (!survey) {
                setError(true);
                setLoading(false);
                return;
            }
            const questions = await SurveyService.getQuestions(id, auth.token);
            if (!questions) {
                setError(true);
                setLoading(false);
                return;
            }

            setSurvey(survey);
            setQuestions(questions);
            setLoading(false);
        }

        if (auth) {
            fetch();
        }
        // eslint-disable-next-line
    }, []);

    /**
     * SUBMIT
     */
    const submit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const res = await SurveyService.postAnswers(id, { data: answers }, auth.token);
        for (const qid in files) {
            if (files[qid] !== '') {
                const formData = new FormData();
                formData.append('id', qid);
                formData.append('uid', res.data.uid);
                formData.append('file', files[qid]);

                await axios.post(`/api/surveys/${id}/upload`, formData, {
                    headers: {
                        'x-auth-token': auth.token,
                        'Content-Type': 'multipart/form-data',
                    },
                });

                console.log(res.data.uid);
            }
        }
        setLoading(false);
        if (res) {
            history.push(`/umfrage/${id}/abgeschlossen?uid=${res.data.uid}`);
        } else {
            setPostError(true);
        }
        return false;
    };

    return (
        <Referrer>
            <header>
                <Container maxWidth='lg'>
                    <Header />
                    {auth && (auth.role === 'ROLE_ADMIN' || auth.role === 'ROLE_LEADER') && <TopNavigation />}
                </Container>
            </header>
            <Container maxWidth='lg' className={classes.container}>
                {loading && (
                    <Grid container spacing={2} justify='center'>
                        <Grid item>
                            <CircularProgress />
                        </Grid>
                    </Grid>
                )}
                {error && (
                    <Grid item xs={12}>
                        <Alert severity='error'>
                            Diese Umfrage ist nicht verfügbar, oder Sie sind nicht an der Teilnahme berechtigt!
                        </Alert>
                    </Grid>
                )}
                {postError && (
                    <Grid item xs={12}>
                        <Alert severity='error'>
                            Es hab einen Fehler bei der Übermittlung der Daten. Bitte versuchen Sie es später erneut.
                        </Alert>
                    </Grid>
                )}
                {!loading && !error && !postError && (
                    <Content title={survey.title}>
                        <form onSubmit={submit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography>{survey.description}</Typography>
                                </Grid>
                                {questions.map((question, index) => (
                                    <Choice setAnswer={setAnswer} setFiles={setFile} key={index} question={question} />
                                ))}
                                <Grid item xs={12} className={classes.container}>
                                    <Button type='submit' variant='contained' color='primary' endIcon={<SendIcon />}>
                                        Senden
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Content>
                )}
            </Container>
        </Referrer>
    );
};

export default PageSurvey;
