import React from 'react';
import { Button as MButton, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    button: {
        backgroundColor: '#39AC39',
        '&:hover': {
            backgroundColor: '#2c862c',
        },
    },
}));

const Button = ({ children, ...props }) => {
    /**
     * CSS
     */
    const classes = useStyles();

    return (
        <MButton {...props} className={classes.button}>
            {children}
        </MButton>
    );
};

export default Button;
