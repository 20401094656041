import axios from 'axios';

class SectionService {
    static async post(tree, token) {
        try {
            await axios.post(
                '/api/sections',
                {
                    tree: tree,
                },
                {
                    headers: {
                        'x-auth-token': token,
                    },
                }
            );
            return true;
        }
        catch(err) {
            return false;
        }
    }

    static async get(token) {
        try {
            const response = await axios.get(
                '/api/sections',
                {
                    headers: {
                        'x-auth-token': token,
                    },
                }
            );
            return response.data;
        }
        catch(err) {
            return false;
        }
    }
}

export default SectionService;
