import React, { useContext } from 'react'
import AuthContext from '../../context/AuthContext'
import { Redirect } from 'react-router';

const Referrer = ({ children }) => {
    /**
     * STATE
     */
    const { auth } = useContext(AuthContext);

    if(!auth) {
        return <Redirect to={`/login?referrer=${window.location.pathname}`} />
    }

    return children;
}

export default Referrer
