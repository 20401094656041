import React, { useMemo, useState } from 'react';
import AuthContext from './context/AuthContext';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PageLogin from './pages/PageLogin';
import PageDashboard from './pages/PageDashboard';
import Logout from './components/Logout/Logout';
import './assets/css/main.css';
import PageSurvey from './pages/PageSurvey';
import PageSurveyFinished from './pages/PageSurveyFinished';

const App = () => {
    const [auth, setAuth] = useState(null);
    const authProvider = useMemo(() => ({ auth, setAuth }), [auth, setAuth]);

    return (
        <AuthContext.Provider value={authProvider}>
            <Router>
                <Switch>
                    <Route exact path='/login' component={PageLogin} />
                    <Route exact path='/logout' component={Logout} />
                    <Route exact path='/umfrage/:id/abgeschlossen' component={PageSurveyFinished} />
                    <Route exact path='/umfrage/:id' component={PageSurvey} />
                    <Route path='/' component={PageDashboard} />
                </Switch>
            </Router>
        </AuthContext.Provider>
    );
};

export default App;
