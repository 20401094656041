import React, { useContext } from 'react';
import AuthContext from '../../context/AuthContext';
import { Redirect } from 'react-router-dom';

const Protected = ({ children }) => {
    const { auth } = useContext(AuthContext);

    if (auth === null) {
        return <Redirect to='/login' />;
    } else {
        return children;
    }
};

export default Protected;
