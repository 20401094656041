import {
    CircularProgress,
    Grid,
    makeStyles,
    Typography,
    Fade,
    Modal,
    Backdrop,
    Button as MButton,
} from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import Button from '../components/Button/Button';
import Content from '../components/Content/Content';
import AuthContext from '../context/AuthContext';
import SurveyService from '../services/SurveyService';
import AddIcon from '@material-ui/icons/Add';
import Question from '../components/Question/Question';
import { Fragment } from 'react';
import SendIcon from '@material-ui/icons/Send';
import MuiAlert from '@material-ui/lab/Alert';
import QuestionService from '../services/QuestionService';

function Alert(props) {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const useStyles = makeStyles((theme) => ({
    titleContainer: {
        backgroundColor: '#39AC39',
        marginTop: 24,
    },
    title: {
        color: '#fff',
        fontWeight: 600,
        padding: 24,
    },
    margin: {
        marginTop: 24,
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #39AC39',
        borderRadius: 5,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

const SurveyDetailForm = () => {
    /**
     * CSS
     */
    const classes = useStyles();

    /**
     * PARAMS
     */
    const { id } = useParams();

    /**
     * STATE
     */
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [deleteError, setDeleteError] = useState(false);
    const [survey, setSurvey] = useState(null);
    const { auth } = useContext(AuthContext);
    const [questions, setQuestions] = useState([]);
    const [saveError, setSaveError] = useState(false);
    const history = useHistory();
    const [toDelete, setToDelete] = useState(0);
    const [open, setOpen] = useState(false);

    /**
     * HOOKS
     */
    useEffect(() => {
        async function fetch() {
            const survey = await SurveyService.getById(id, auth.token);
            if (!survey) {
                setLoading(false);
                setError(true);
            } else {
                const result = await SurveyService.getQuestions(id, auth.token);
                if (!result) {
                    setLoading(false);
                    setError(true);
                } else {
                    setSurvey(survey);
                    setQuestions(result);
                    setLoading(false);
                }
            }
        }
        fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * CONTROLS
     */
    const addQuestion = (index) => {
        if (index < 0) {
            setQuestions([
                ...questions,
                {
                    type: '',
                    answers: [],
                },
            ]);
        } else {
            let newQuestions = [...questions];
            newQuestions.splice(index, 0, {
                type: '',
                answers: [],
            });
            setQuestions(newQuestions);
        }
    };

    const deleteQuestionModal = (index) => {
        setToDelete(index);
        setOpen(true);
    };

    const deleteFromArray = (index) => {
        let newQuestions = [...questions];
        newQuestions.splice(index, 1);
        setQuestions(newQuestions);
    };

    const deleteQuestion = async () => {
        const question = questions[toDelete];
        if (question.id === undefined) {
            deleteFromArray(toDelete);
            setOpen(false);
        } else {
            const response = await QuestionService.delete(question.id, auth.token);
            if (!response) {
                setDeleteError(true);
            } else {
                deleteFromArray(toDelete);
                setOpen(false);
            }
        }
    };

    const update = (index, question) => {
        let newQuestions = [...questions];
        newQuestions[index] = question;

        setQuestions(newQuestions);
    };

    const closeModal = () => {
        setOpen(false);
    };

    /**
     * SUBMIT
     */
    const submit = async (e) => {
        e.preventDefault();

        const response = await QuestionService.post(id, questions, auth.token);

        if (!response) {
            setSaveError(true);
        } else {
            history.push('/');
        }

        return false;
    };

    return (
        <Fragment>
            <Content title='Umfrage bearbeiten'>
                {loading && (
                    <Grid container justify='center'>
                        <CircularProgress />
                    </Grid>
                )}
                {error && (
                    <Grid container justify='center'>
                        <Typography>Fehler beim Laden der Daten!</Typography>
                    </Grid>
                )}
                {survey && (
                    <form onSubmit={submit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} className={classes.titleContainer}>
                                <Typography className={classes.title}>{survey.title}</Typography>
                            </Grid>
                            {questions.map((question, index) => (
                                <Fragment key={index}>
                                    <Grid className={classes.margin} item xs={12}>
                                        <Grid container justify='center'>
                                            <Grid item>
                                                <Button
                                                    onClick={(e) => addQuestion(index)}
                                                    variant='contained'
                                                    color='primary'
                                                    startIcon={<AddIcon />}
                                                >
                                                    Element hinzufügen
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid className={classes.margin} item xs={12}>
                                        <Question
                                            onDelete={deleteQuestionModal}
                                            onUpdate={update}
                                            question={question}
                                            index={index}
                                        />
                                    </Grid>
                                </Fragment>
                            ))}
                            <Grid item xs={12} className={classes.margin}>
                                <Grid container justify='center'>
                                    <Grid item>
                                        <Button
                                            onClick={(e) => addQuestion(-1)}
                                            variant='contained'
                                            color='primary'
                                            startIcon={<AddIcon />}
                                        >
                                            Element hinzufügen
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {saveError && (
                                <Grid item xs={12}>
                                    <Alert severity='error'>Fehler beim Speichern der Umfrage!</Alert>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Button type='submit' variant='contained' color='primary' endIcon={<SendIcon />}>
                                    Speichern
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Content>
            <Modal
                aria-labelledby='delete-modal-title'
                aria-describedby='delete-modal-description'
                open={open}
                closeAfterTransition
                className={classes.modal}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <Typography component='h4' variant='h4' id='transition-modal-title'>
                            Löschen?
                        </Typography>
                        <div id='transition-modal-description'>
                            <p>
                                <Typography>
                                    Möchten Sie die Frage wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht
                                    werden.
                                </Typography>
                            </p>
                            <Grid container spacing={2} justify='space-between'>
                                <Grid item>
                                    <MButton onClick={closeModal} variant='outlined' color='primary'>
                                        Abbrechen
                                    </MButton>
                                </Grid>
                                <Grid item>
                                    <MButton onClick={deleteQuestion} variant='outlined' color='secondary'>
                                        Löschen
                                    </MButton>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </Fragment>
    );
};

export default SurveyDetailForm;
