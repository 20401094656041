import { Grid, TextField } from '@material-ui/core';
import React, { Fragment } from 'react';
import { useState } from 'react';

const FreeText = ({ question, setAnswer }) => {
    /**
     * STATE
     */
    const [value, setValue] = useState('');

    /**
     * CONTROLS
     */
    const change = (e) => {
        setValue(e.target.value);
        setAnswer(question.id, [e.target.value]);
    };

    return (
        <Fragment>
            <Grid item xs={12} sm={12}>
                <TextField value={value} onChange={change} fullWidth label='Antwort' multiline rows={4} variant='outlined' />
            </Grid>
        </Fragment>
    );
};

export default FreeText;
